import { ethers } from 'ethers';

/**
 * Yup vaidation for an Ethereum address
 *
 * @param message - The message if invalid
 * @returns - An object that can be used with Yup
 */

export const yupTestEthersAddress = (message: string) => ({
  name: 'Ethers isAddress',
  test: (value: string | undefined) => {
    if (!value) {
      return false;
    }
    if (!value.startsWith('0x')) {
      /** could be an ENS name - any string is a valid ENS name */
      return true;
    }
    return ethers.utils.isAddress(value);
  },
  message,
});
