import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { normalize } from 'styled-normalize';
import { SWRConfig } from 'swr';

import WalletScreen from './screens/WalletScreen';
import theme, { AppThemeProps } from './theme/theme';
import fetcher from './utils/fetcher';

const GlobalStyle = createGlobalStyle<AppThemeProps>`
  ${normalize}

  body {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
  }

  // root div is 100% height, use flexbox
  body > #root {
    display: flex;
    height: 100vh;
  }

  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

const App: FC = () => {
  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <title>Unwallet</title>
            <link
              rel="stylesheet"
              href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css"
            />
          </Helmet>
          <GlobalStyle />
          <WalletScreen />
        </HelmetProvider>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
