/**
 * Basic JSON fetcher for use with `useSWR`
 * @param url - The url to fetch json
 * @returns The json object fectched from the url
 */

const fetcher = async (url: string): Promise<any> => {
  const response = await fetch(url);
  const json = await response.json();
  return json;
};

export default fetcher;
