import { FC } from 'react';
import styled from 'styled-components';

import { AppThemeProps } from '../theme/theme';
import Card from './ui/Card';
import CardBody from './ui/CardBody';
import CardHead from './ui/CardHead';
import Row from './ui/Row';
import Spinner from './ui/Spinner';
import TokenBalance from './ui/TokenBalance';

interface WalletBalanceProps {
  balance: string;
  isLoading: boolean;
}

const Container = styled(Row)<AppThemeProps>`
  display: flex;
  flex: 1;
  margin: 0 1rem 1rem 0;
`;

const Text = styled.div`
  font-size: 2rem;
`;

const WalletBalance: FC<WalletBalanceProps> = ({ balance, isLoading }) => {
  return (
    <Container>
      <Card>
        <CardHead>
          <span>
            Wallet balance
            {isLoading && balance && (
              <>
                {' '}
                <Spinner />
              </>
            )}
          </span>
        </CardHead>
        <CardBody>
          {isLoading && !balance ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <Text>
              <TokenBalance symbol="ETH" balance={balance} />
            </Text>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default WalletBalance;
