import { useMemo } from 'react';
import useSWR from 'swr';

import { TOKEN_LIST_ENDPOINT } from '../constants/services';

export interface TokenDataEntity {
  address: string;
  symbol: string;
  decimals: number;
  name: string;
  iconUrl: string;
  aggregators: string[];
  occurrences: number;
}

export interface TokenDataByContractAddress {
  [tokenContractAddress: string]: TokenDataEntity;
}

/**
 * Hook to fetch token data and ERC20 token contract addresses from a third-party API
 */

export const useTokenContracts = () => {
  const { data: tokenData, isValidating: tokenDataIsLoading } =
    useSWR<TokenDataEntity[]>(TOKEN_LIST_ENDPOINT);

  const tokenDataByContractAddresses =
    useMemo<TokenDataByContractAddress>(() => {
      if (!tokenData) {
        return {};
      }
      const result: TokenDataByContractAddress = {};
      tokenData.forEach((tokenDataEntity) => {
        if (tokenDataEntity.address) {
          result[tokenDataEntity.address] = tokenDataEntity;
        }
      });
      return result;
    }, [tokenData]);

  const tokenContractAddresses = useMemo(
    () => Object.keys(tokenDataByContractAddresses),
    [tokenDataByContractAddresses]
  );

  return {
    tokenData,
    tokenDataIsLoading,
    tokenDataByContractAddresses,
    tokenContractAddresses,
  };
};
