import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.span`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  display: inline-block;
  animation: rotating 2s linear infinite;
`;

const Spinner: FC = () => {
  return <Container className="lnr lnr-sync" />;
};

export default Spinner;
