import styled from 'styled-components';

import { AppThemeProps } from '../../theme/theme';

interface ButtonProps extends AppThemeProps {
  outline?: boolean;
}

const Button = styled.button<ButtonProps>`
  border: 1px solid
    ${({ theme, outline }) =>
      outline ? theme.colors.border : theme.colors.transparent};
  color: ${({ theme, outline }) =>
    outline ? theme.colors.foreground : theme.colors.background};
  background-color: ${({ theme, outline }) =>
    outline ? theme.colors.transparent : theme.colors.foreground};
  border-radius: 0.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  .lnr {
    font-size: 20px;
  }
  transition: opacity 0.15s, background-color 0.15s, border 0.15s, color 0.15s;
`;

export default Button;
