import styled from 'styled-components';

import { AppThemeProps } from '../../theme/theme';
import Col from './Col';

const CardBody = styled(Col)<AppThemeProps>`
  margin-top: 1rem;
  flex-grow: 0;
`;

export default CardBody;
