import { FC } from 'react';
import styled from 'styled-components';

import { AppThemeProps } from '../theme/theme';
import Card from './ui/Card';
import CardBody from './ui/CardBody';
import CardHead from './ui/CardHead';
import Row from './ui/Row';
import Spinner from './ui/Spinner';

interface WalletGuardiansProps {
  guardians: string[] | null;
  isLoading: boolean;
}

const Container = styled(Row)<AppThemeProps>`
  display: flex;
  flex: 1;
  margin: 0 1rem 1rem 0;
`;

const Text = styled.div`
  font-size: 2rem;
`;

const WalletGuardians: FC<WalletGuardiansProps> = ({
  guardians,
  isLoading,
}) => {
  const hasGuardians = guardians && guardians.length;
  return (
    <Container>
      <Card>
        <CardHead>Number of guardians</CardHead>
        <CardBody>
          {isLoading || !guardians ? (
            <div>
              <Spinner />
            </div>
          ) : hasGuardians ? (
            <Text>{guardians.length}</Text>
          ) : (
            <Text>Zero</Text>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default WalletGuardians;
