import styled from 'styled-components';

import { AppThemeProps } from '../../theme/theme';
import Row from './Row';

const CardHead = styled(Row)<AppThemeProps>`
  font-weight: bold;
  flex-grow: 0;
`;

export default CardHead;
