const theme = {
  colors: {
    foreground: '#fff',
    background: '#111',
    muted: '#999',
    text: '#fff',
    border: '#b2b2b2',
    transparent: 'rgba(0,0,0,0)',
    error: '#f33',
    card: '#333',
    inputBackground: '#222',
    black: '#000',
    white: '#fff',
  },
};

export default theme;

// theme type
export type AppTheme = typeof theme;

// interface for styled.View<AppThemeProps>`...
export interface AppThemeProps {
  theme: AppTheme;
}
