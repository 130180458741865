import { useCallback, useEffect, useState } from 'react';

import { argentGuardianManagerContract } from '../services/contracts';

/**
 * Executes the `getGuardians()` method of the Argent Guardian Manager Contract
 * with the provided wallet address
 *
 * @param walletAddress - Address or ENS name
 */

export const useArgentGuardians = (walletAddress: string) => {
  const [guardians, setGuardians] = useState<string[] | null>(null);
  const [guardiansIsLoading, setGuardiansIsLoading] = useState<boolean>(false);

  const fetchGuardians = useCallback(async () => {
    setGuardiansIsLoading(true);
    const guardians = await argentGuardianManagerContract.getGuardians(
      walletAddress
    );
    setGuardians(guardians);
    setGuardiansIsLoading(false);
  }, [walletAddress]);

  const resetGuardians = useCallback(() => {
    setGuardians(null);
  }, []);

  useEffect(() => {
    fetchGuardians();
  }, [fetchGuardians]);

  return { guardians, guardiansIsLoading, resetGuardians };
};
