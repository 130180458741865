import { Form, Formik, FormikProps } from 'formik';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import provider from '../services/provider';
import { AppThemeProps } from '../theme/theme';
import { yupTestEthersAddress } from '../utils/validation';
import Button from './ui/Button';
import Card from './ui/Card';
import CardBody from './ui/CardBody';
import CardHead from './ui/CardHead';
import Col from './ui/Col';
import Row from './ui/Row';
import Spinner from './ui/Spinner';

const WalletFormSchema = Yup.object().shape({
  walletAddress: Yup.string()
    .required('Wallet address is required')
    .test(yupTestEthersAddress('Invalid wallet address')),
});

interface WalletFormProps {
  walletAddress: string;
}

interface WalletAddressInputProps {
  isLoading: boolean;
  walletAddress: string;
  onChange: (walletAddress: string) => Promise<void>;
}

interface WithErrorProps extends AppThemeProps {
  error?: string;
}

const Container = styled.div<AppThemeProps>`
  display: flex;
  margin: 0 1rem 1rem 0;
`;

const Label = styled.label<WithErrorProps>`
  color: ${({ error, theme }) =>
    error ? theme.colors.error : theme.colors.text};
`;

const InputContainer = styled(Col)`
  position: relative;
`;

const Input = styled.input<WithErrorProps>`
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.error : theme.colors.transparent};
  border-radius: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid
      ${({ error, theme }) =>
        error ? theme.colors.error : theme.colors.border};
    background-color: ${({ theme }) => theme.colors.background};
  }
  margin-right: 0.5rem;
  transition: border 0.15s, background-color 0.15s;
  ::-webkit-search-cancel-button {
    display: none;
  }
`;

const InputIcon = styled.div`
  position: absolute;
  left: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const WalletAddressInput: FC<WalletAddressInputProps> = ({
  isLoading,
  walletAddress,
  onChange,
}) => {
  const [walletAddressError, setWalletAddressError] = useState<string>('');

  const onSubmit = useCallback(
    async ({ walletAddress }) => {
      /** Validate the address before calling `onChange` callback */
      setWalletAddressError('');
      const address = await provider.resolveName(walletAddress);
      if (!address) {
        setWalletAddressError(`Unable to resolve ${walletAddress}`);
      } else {
        await onChange(walletAddress);
      }
    },
    [onChange]
  );

  return (
    <Container>
      <Card>
        <Formik
          initialValues={{
            walletAddress,
          }}
          validationSchema={WalletFormSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }: FormikProps<WalletFormProps>) => {
            const fieldError = walletAddressError || errors.walletAddress;
            const error =
              fieldError && touched.walletAddress ? fieldError : undefined;
            return (
              <Form>
                <Col>
                  <CardHead>
                    <Label htmlFor="walletAddress" error={error}>
                      {error || 'Wallet address'}
                    </Label>
                  </CardHead>
                  <CardBody>
                    <Row>
                      <InputContainer>
                        <Input
                          type="search"
                          id="walletAddress"
                          name="walletAddress"
                          autoComplete={'off'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.walletAddress}
                          error={error}
                          spellCheck={false}
                          placeholder="Address or ENS name"
                        />
                        <InputIcon>
                          <span className="lnr lnr-magnifier" />
                        </InputIcon>
                      </InputContainer>
                      <Button type="submit" outline={isLoading || isSubmitting}>
                        {isLoading || isSubmitting ? (
                          <Spinner />
                        ) : (
                          <span className="lnr lnr-arrow-right" />
                        )}
                      </Button>
                    </Row>
                  </CardBody>
                </Col>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </Container>
  );
};

export default WalletAddressInput;
