import * as ethers from 'ethers';
import SINGLE_CALL_BALANCE_CHECKER_ABI from 'single-call-balance-checker-abi';

import ARGENT_GUARDIAN_MANAGER_ABI from '../abi/argent-guardian-manager-abi.json';
import {
  ARGENT_GUARDIAN_MANAGER_CONTRACT_ADDRESS_MAINNET,
  SINGLE_CALL_BALANCE_CHECKER_CONTRACT_ADDRESS_MAINNET,
} from '../constants/contracts';
import provider from './provider';

export const singleCallBalanceCheckerContract = new ethers.Contract(
  SINGLE_CALL_BALANCE_CHECKER_CONTRACT_ADDRESS_MAINNET,
  SINGLE_CALL_BALANCE_CHECKER_ABI
).connect(provider);

export const argentGuardianManagerContract = new ethers.Contract(
  ARGENT_GUARDIAN_MANAGER_CONTRACT_ADDRESS_MAINNET,
  ARGENT_GUARDIAN_MANAGER_ABI
).connect(provider);
