import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

import WalletAddressInput from '../components/WalletAddressInput';
import WalletBalance from '../components/WalletBalance';
import WalletGuardians from '../components/WalletGuardians';
import WalletTokenBalances from '../components/WalletTokenBalances';
import Col from '../components/ui/Col';
import Row from '../components/ui/Row';
import { useArgentGuardians } from '../hooks/useArgentGuardians';
import { useTokenBalances } from '../hooks/useTokenBalances';
import { useWalletBalance } from '../hooks/useWalletBalance';

const Container = styled(Col)`
  margin: 0 auto;
  padding: 2rem 1rem 1rem 2rem;
  max-width: 940px;
  overflow: hidden;
  @media (max-width: 480px) {
    padding: 1rem 0 0 1rem;
  }
`;

const Content = styled(Row)`
  overflow: hidden;
  flex-shrink: 1;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const WalletScreen: FC = () => {
  const [walletAddress, setWalletAddress] = useState(
    process.env.REACT_APP_DEFAULT_WALLET_ADDRESS as string
  );
  const { balances, balancesIsLoading, resetBalances } =
    useTokenBalances(walletAddress);
  const { guardians, guardiansIsLoading, resetGuardians } =
    useArgentGuardians(walletAddress);
  const { balance, balanceIsLoading, resetBalance } =
    useWalletBalance(walletAddress);

  const onWalletAddressChange = useCallback(
    async (walletAddress: string) => {
      resetBalance();
      resetGuardians();
      resetBalances();
      setWalletAddress(walletAddress);
    },
    [resetBalance, resetBalances, resetGuardians]
  );

  return (
    <Container>
      <WalletAddressInput
        isLoading={balancesIsLoading}
        walletAddress={walletAddress}
        onChange={onWalletAddressChange}
      />
      <Content>
        <Col>
          <WalletBalance balance={balance} isLoading={balanceIsLoading} />
          <WalletGuardians
            guardians={guardians}
            isLoading={guardiansIsLoading}
          />
        </Col>
        <Col>
          <WalletTokenBalances
            balances={balances}
            isLoading={balancesIsLoading}
          />
        </Col>
      </Content>
    </Container>
  );
};

export default WalletScreen;
