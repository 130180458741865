import * as ethers from 'ethers';
import { useCallback, useEffect, useState } from 'react';

import provider from '../services/provider';

/**
 * Hook to fetch and update the balance of the provided `walletAddress`.
 * When `live` is true, `balance` is re-fetched each time a new block is mined.
 *
 * @param walletAddress - Address or ENS name
 * @param live - Keep balance updated, off by default (makes many network requests)
 */

export const useWalletBalance = (
  walletAddress: string,
  live: boolean = false
) => {
  const [balance, setBalance] = useState<string>('');
  const [balanceIsLoading, setBalanceIsLoading] = useState<boolean>(false);

  const fetchBalance = useCallback(async () => {
    if (!walletAddress) {
      return;
    }
    setBalanceIsLoading(true);
    const balanceInWei = await provider.getBalance(walletAddress);
    const balanceInEth = ethers.utils.formatEther(balanceInWei);
    setBalance(balanceInEth);
    setBalanceIsLoading(false);
  }, [walletAddress]);

  const resetBalance = useCallback(() => {
    setBalance('');
  }, []);

  useEffect(() => {
    fetchBalance();
    if (live) {
      provider.on('block', fetchBalance);
    }
    return () => {
      if (live) {
        provider.off('block', fetchBalance);
      }
    };
  }, [fetchBalance, live, walletAddress]);

  return { balance, balanceIsLoading, resetBalance };
};
