import * as ethers from 'ethers';
import { useCallback, useEffect, useState } from 'react';

import { singleCallBalanceCheckerContract } from '../services/contracts';
import {
  TokenDataByContractAddress,
  TokenDataEntity,
  useTokenContracts,
} from './useTokenContracts';

/** Send addresses to the contract in batches */
const TOKEN_BATCH_SIZE = 1000;

export interface FetchBalancesProps {
  /** Address or ENS name */
  walletAddress: string;
  /** Addresses of ERC20 token contracts */
  tokenContractAddresses: string[];
  /** Token data for each address provided in `tokenContractAddresses` */
  tokenDataByContractAddresses: TokenDataByContractAddress;
}

export interface BalanceEntity extends TokenDataEntity {
  /** Balance of token */
  balance: string;
}

/**
 * Executes the `balances()` method of the Single Call Balance Checker Contract
 * with the provided wallet and token contract addresses
 *
 * @param args - FetchBalancesProps
 */

const fetchBalances = async ({
  walletAddress,
  tokenContractAddresses,
  tokenDataByContractAddresses,
}: FetchBalancesProps): Promise<BalanceEntity[]> => {
  const balanceEntities: BalanceEntity[] = [];

  /**
   * There are currently almost 7,000 token contract addresses.
   * Create an array of Promises that will request the balances in batches
   */
  const requestPromises = [];
  for (let i = 0; i < tokenContractAddresses.length; i += TOKEN_BATCH_SIZE) {
    /** Take a slice of addresses <= TOKEN_BATCH_SIZE length */
    const tokenContractAddressesSlice = tokenContractAddresses.slice(
      i,
      i + TOKEN_BATCH_SIZE
    );
    /** Create a Promise that sends this batch to the single balance checker contract */
    const requestPromise = singleCallBalanceCheckerContract.balances(
      [walletAddress],
      tokenContractAddressesSlice
    );
    requestPromises.push(requestPromise);
  }

  /** Wait for all the requests in parallel */
  const responses = await Promise.all(requestPromises);

  /** Flatted the nested arrays into a single array of balances */
  const balances: ethers.BigNumber[] = responses.flat(1);

  /** Process non-zero balances */
  balances.forEach((balance, index) => {
    if (!balance.isZero()) {
      /** use the index to get the token contract address */
      const tokenContractAddress = tokenContractAddresses[index];
      /** use the contract address to get the token data */
      const tokenDataEntity =
        tokenDataByContractAddresses[tokenContractAddress];
      /** format using the decimals from the token data */
      const tokenBalance = ethers.utils.formatUnits(
        balance,
        tokenDataEntity.decimals
      );
      /** add an entry with the token data and its balance */
      balanceEntities.push({
        ...tokenDataEntity,
        balance: tokenBalance,
      });
    }
  });
  return balanceEntities;
};

/**
 * Hook to update and return wallet token balances at the provided `walletAddress`
 *
 * @param walletAddress - Address or ENS name
 */

export const useTokenBalances = (walletAddress: string) => {
  const [balances, setBalances] = useState<BalanceEntity[]>([]);
  const [balancesIsLoading, setBalancesIsLoading] = useState<boolean>(false);
  const { tokenDataByContractAddresses, tokenContractAddresses } =
    useTokenContracts();

  const updateBalances = useCallback(async () => {
    if (!tokenContractAddresses.length || !walletAddress) {
      return;
    }
    setBalancesIsLoading(true);
    const updatedBalances = await fetchBalances({
      walletAddress,
      tokenDataByContractAddresses,
      tokenContractAddresses,
    });
    setBalances(updatedBalances);
    setBalancesIsLoading(false);
  }, [tokenContractAddresses, tokenDataByContractAddresses, walletAddress]);

  const resetBalances = useCallback(() => {
    setBalances([]);
  }, []);

  useEffect(() => {
    updateBalances();
  }, [
    tokenContractAddresses,
    tokenDataByContractAddresses,
    updateBalances,
    walletAddress,
  ]);

  return { balances, balancesIsLoading, resetBalances };
};
