import { FC } from 'react';
import styled from 'styled-components';

import { BalanceEntity } from '../hooks/useTokenBalances';
import { AppThemeProps } from '../theme/theme';
import Card from './ui/Card';
import CardBody from './ui/CardBody';
import CardHead from './ui/CardHead';
import Row from './ui/Row';
import Spinner from './ui/Spinner';
import TokenBalance from './ui/TokenBalance';

interface TokenListProps {
  balances?: BalanceEntity[];
  isLoading: boolean;
}

const Container = styled(Row)<AppThemeProps>`
  display: flex;
  margin: 0 1rem 1rem 0;
`;

const ScrollableContentWrap = styled(CardBody)`
  position: relative;
  flex-grow: 1;
`;

const ScrollableContent = styled.div<AppThemeProps>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.inputBackground};
    border-radius: 3px;
  }
`;

const TokenBalanceRow = styled.div`
  margin-bottom: 0.25rem;
`;

const WalletTokenBalances: FC<TokenListProps> = ({
  balances = [],
  isLoading,
}) => {
  return (
    <Container>
      <Card>
        <CardHead>ERC20 tokens</CardHead>
        <ScrollableContentWrap>
          <ScrollableContent>
            {isLoading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              balances.map(({ symbol, balance }, index) => {
                return (
                  <TokenBalanceRow key={`${symbol}-${index}`}>
                    <TokenBalance symbol={symbol} balance={balance} />
                  </TokenBalanceRow>
                );
              })
            )}
          </ScrollableContent>
        </ScrollableContentWrap>
      </Card>
    </Container>
  );
};

export default WalletTokenBalances;
