import styled from 'styled-components';

import { AppThemeProps } from '../../theme/theme';
import Col from './Col';

const Card = styled(Col)<AppThemeProps>`
  background-color: ${({ theme }) => theme.colors.card};
  border-radius: 1rem;
  padding: 1.5rem;
`;

export default Card;
