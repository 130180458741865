import numeral from 'numeral';
import { FC, useMemo } from 'react';
import styled from 'styled-components';

import { AppThemeProps } from '../../theme/theme';

const Symbol = styled.span<AppThemeProps>`
  color: ${({ theme }) => theme.colors.muted};
  font-size: 0.8em;
  font-weight: bold;
`;

interface TokenBalanceProps {
  symbol: string;
  balance: string;
}

const TokenBalance: FC<TokenBalanceProps> = ({ symbol, balance }) => {
  const displayBalance = useMemo(() => {
    if (!balance) {
      return '';
    }

    /** Try to prettify the value */
    const displayBalance = numeral(balance).format('0,0[.][0000]');

    /** Too small to format e.g. 0.000000146464609461 MIR - display raw value */
    if (displayBalance === 'NaN' || displayBalance === '0') {
      return balance;
    }
    return displayBalance;
  }, [balance]);

  if (!balance) {
    return null;
  }

  return (
    <span data-balance={balance}>
      {displayBalance} <Symbol>{symbol}</Symbol>
    </span>
  );
};

export default TokenBalance;
